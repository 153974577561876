import type { User } from '@/types/User'
import { useThFetch } from '@/composables/useThFetch'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { UnauthorizedError } from '@/auth/errors'
import { makeDefaultAuthStrategy } from '@/auth/makeDefaultAuthStrategy'
import { makeKeycloakAuthStrategy } from '@/auth/makeKeycloakAuthStrategy'
import type { AuthStrategy } from '@/auth/AuthStrategy'
import { Env } from '@/env'

const isKeycloakEnabled = Env.VITE_VUE_APP_KEYCLOAK_ENABLED === 'true'

export type AuthStoreState = ReturnType<typeof useAuthStore>['$state']

const useAuthStoreBase = defineStore('auth', () => {
  let authStrategy: AuthStrategy

  const isInitialized = ref(false)
  const user = ref<User>()
  // When staff logs in as support for a client, this will contain the user id 
  // for the merchant, which has to be sent to the backend as a header.
  const clientAccountId = ref<string>()

  async function fetchUserData() {
    if (!isAuthenticated()) {
      return new UnauthorizedError()
    }

    const { data, error, statusCode } = await useThFetch('/v0/me').json()

    if (data.value) {
      const userData: User = data.value.results[0].user

      user.value = userData

      return
    }

    return statusCode.value === 401 ? new UnauthorizedError() : (error.value as Error)
  }

  function getToken() {
    return authStrategy?.getToken() ?? null
  }

  function isAuthenticated() {
    return authStrategy?.isAuthenticated() ?? false
  }

  function login(...args: Parameters<typeof authStrategy.login>) {
    return authStrategy.login(...args)
  }

  function getLoginUrl() {
    return authStrategy.getLoginUrl() ?? null
  }

  async function logout() {
    await authStrategy.logout()
    user.value = undefined
  }

  /**
   * If staff has logged in as support for a client, the client account id needs to be passed.
   */
  async function init(strategy: AuthStrategy['name'], _clientAccountId?: string) {
    if (isInitialized.value) {
      return
    }

    clientAccountId.value = _clientAccountId

    if (strategy === 'keycloak' && !isKeycloakEnabled) {
      throw new Error('Authentication via Keycloak is not allowed.')
    }

    authStrategy = strategy === 'default' ? makeDefaultAuthStrategy() : makeKeycloakAuthStrategy()

    await authStrategy.init()

    await fetchUserData()

    isInitialized.value = true
  }

  async function getAuthHeaders() {
    const token = await getToken()
    const headers: HeadersInit = {}

    if (!token) {
      return headers
    }

    headers['Authorization'] = `Bearer ${token}`

    if (authStrategy?.name === 'keycloak') {
      headers['x-whitelabel'] = 'unified-commerce'
    }

    if (clientAccountId.value) {
      headers['x-client-account'] = clientAccountId.value
    }

    return headers
  }

  return {
    user: computed(() => user.value),
    init,
    isAuthenticated,
    login,
    getLoginUrl,
    logout,
    fetchUserData,
    isInitialized,
    getAuthHeaders
  }
})

/**
 * Both strategies have different login signatures. This wrapper function provides the ability
 * to specify upon use which strategy is being used and what the login signature should be.
 */
type AuthStoreReturnType = ReturnType<typeof useAuthStoreBase>

export const useAuthStore = <TStrategy extends AuthStrategy = AuthStrategy>(
  ...args: Parameters<typeof useAuthStoreBase>
) => {
  return useAuthStoreBase(...args) as AuthStoreReturnType & {
    login: TStrategy['login']
  }
}
