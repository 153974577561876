import { useAuthStore } from '@/stores/useAuthStore'
import type { NavigationGuard } from 'vue-router'
import { useContextStore } from '@/stores/useContextStore'

export const authNavigationGuard: NavigationGuard = async (to) => {
  const authStore = useAuthStore()
  const contextStore = useContextStore()

  if (!contextStore.isInitialized) {
    await contextStore.init()
  }

  if (!authStore.isInitialized) {
    // If staff is logged in as support for a client, the Dashboard will provide the client account id
    // which is passed to the /me endpoint
    const clientAccountId = contextStore.isSupport ? contextStore.clientAccountId : undefined

    const authStrategy = contextStore.isEmbeddedIn('unzer-dashboard') ? 'keycloak' : 'default'

    await authStore.init(authStrategy, clientAccountId)
  }

  // If we do not have the user data after initialization, we need to redirect him to the login page
  if (!authStore.user) {
    const loginRoute = await authStore.getLoginUrl()

    // If the login url is a string, its considered to be external
    if (typeof loginRoute === 'string') {
      window.location.assign(loginRoute)

      return
    }

    // We need to check if the user is not already being redirected to the login
    // route to avoid an infinite loop
    if (loginRoute.name !== to.name) {
      return loginRoute
    }
  }
}
